import { useMemo, useState } from "react";
import { EmailLoginEntityReq } from "../types/email-login-otp";
import {
  API_BASE_URL,
  API_ENDPOINTS,
  API_PUBLIC_AUTH_TOKEN,
} from "../constants/api";
import { BaseEntityRes } from "../types/base";
import Http from "../services/http";
import { useUserHooks } from "./user-hooks";
import { useDispatch, useSelector } from "react-redux";
import {
  actionSetEmailLoginModalOpen,
  actionSetEmailOtpModalOpen,
  actionSetLoginModalOpen,
  actionSetOtpErrorMessage,
} from "../store/actions/login-option-actions";
import {
  COOKIE_GST_ATKN,
  EMAIL_LOGIN_VERIFICATION_RESULT,
  LANGUAGE_TRANS,
  REDIRECT,
} from "../constants/storage";
import { Cookie, LocalStorage } from "../services/storage";
import { getDomain } from "../utils/UtilsBrowser";
import { LoginOptionState } from "../store/reducers/login-option-reducer";
import { ApplicationState } from "../store";
import { UtilsUseHistory } from "../utils/UtilsUseHistory";
import { RCODES } from "../constants/rcodes";
import { UtilsDateTime } from "../utils/UtilsDateTIme";
import Cookies from "js-cookie";
import { LayoutState } from "../store/reducers/layout-reducer";
import { UserState } from "../store/reducers/user-reducer";
import { useAuth } from "./auth-hooks";
import { useLocation } from "react-router-dom";
import { getRedirectUrl } from "../utils/RedirectURL";
import { EMAIL_LOGIN_SUPPORTED_COUNTRIES } from "../configs/layout";
import { UtilsUser } from "../utils/UtilsUser";
import { IS_PRE, IS_PROD } from "../constants/environment";

export const useLoginOptionHooks = () => {
  // third party hooks
  const dispatch = useDispatch();
  const location = useLocation();

  // custom hooks
  const { setUserEmailLoginInfoData } = useUserHooks();
  const { login, loginV1 } = useAuth();

  // states
  const [isVerifying, setIsVerifying] = useState(false);
  const layoutState = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );
  const { isEmailLoginModalOpen, isEmailOtpModalOpen, banDuration } =
    useSelector<ApplicationState, LoginOptionState>(
      (state) => state.loginOption
    );
  const { userCountryCode } = useSelector<ApplicationState, UserState>(
    (state) => state.user
  );

  // variables
  const { go, push } = UtilsUseHistory();

  /**
   * Object with the duration's value and unit of measurement
   * @property {Object} duration
   * @property {string} duration.value  The numerical value of the duration
   * @property {string} duration.did    The DID key for the unit of measurement of the duration
   *                                    e.g.: minutes, hours, or days
   */
  const duration = useMemo(() => {
    const value = banDuration.split(" ")[0];
    let did = "";

    if (banDuration.includes("day")) {
      did = "PRIVACY_LOGIN_EMAIL_OTP_LOCKED_TEXT_DAYS";
    } else if (banDuration.includes("hour") || banDuration.includes("hours")) {
      did = "PRIVACY_LOGIN_EMAIL_OTP_LOCKED_TEXT_HOURS";
    } else if (banDuration.includes("minutes")) {
      did = "PRIVACY_LOGIN_EMAIL_OTP_LOCKED_TEXT_MINS";
    }

    return {
      value,
      did,
    };
  }, [banDuration]);

  /**
   * Handle login for countries that support logging in via Email.
   */
  const handleLoginBasedOnGeoIp = () => {
    if (UtilsUser.isUserLoggedIn()) return;

    const redirect = getRedirectUrl();
    const countryCode = layoutState.selectedCountry.code || userCountryCode;

    // Login via SA for unsupported countries
    if (!EMAIL_LOGIN_SUPPORTED_COUNTRIES.includes(countryCode)) {
      if (IS_PRE || IS_PROD) {
        loginV1(redirect);
      } else {
        login(redirect);
      }

      return;
    }

    // Else, show login options (Email/SA)
    toggleLoginModal({
      isLoginModalOpen: true,
      loginModalRedirect: redirect ?? location.pathname,
    });
  };

  const handleEmailSignin = async (
    fname: string,
    lname: string,
    email: string,
    type: "send" | "resend"
  ) => {
    const tz = UtilsDateTime.getLocalTimezone();
    const site_country =
      layoutState.selectedCountry.code ?? layoutState.countryCode;
    let language = Cookies.get(LANGUAGE_TRANS) || layoutState.languageTrans;

    if (layoutState.countryCode === "US" && language === "en") {
      language = "en-rUS";
    }

    const params: EmailLoginEntityReq = {
      fname: fname,
      lname: lname,
      email: email,
      tz,
      site_country,
      language,
    };
    setIsVerifying(true);
    try {
      const extraHeaders = {
        Authorization: API_PUBLIC_AUTH_TOKEN,
      };
      setIsVerifying(true);
      const url = `${API_BASE_URL}${API_ENDPOINTS.EMAIL_LOGIN_SEND_OTP}`;
      const res: BaseEntityRes = await Http.post(url, params, extraHeaders);

      // no need to re-toggle OTP modal
      if (type === "resend") return;

      switch (res.rcode) {
        case RCODES.SUCCESS:
          LocalStorage.set(EMAIL_LOGIN_VERIFICATION_RESULT, "false"); // false value means user is still validating OTP.
          setUserEmailLoginInfoData(params);
          toggleEmailOtpModal(true);
          toggleEmailLoginModal(false);
          setIsVerifying(false);
          break;
        default:
          break;
      }
    } catch (e: any) {
      setIsVerifying(false);

      switch (e.rcode) {
        case RCODES.GUEST_BANNED_15MINS:
        case RCODES.GUEST_BANNED_30MINS:
        case RCODES.GUEST_BANNED_1HR:
        case RCODES.GUEST_BANNED_4HR:
        case RCODES.GUEST_BANNED_1D:
          // BREAKDOWN:
          //   rmsg = "Bad Request - guest banned for 15 minutes"
          //   split: ["Bad", "Request", "-", "guest", "banned", "for", "15", "minutes"]
          //   slice(6): ["15", "minutes"]
          //   join(" "): "15 minutes"
          const duration = e.rmsg.split(" ").slice(6).join(" ");
          dispatch(actionSetOtpErrorMessage(duration));
          break;
        default:
          console.error(e);
          break;
      }
    }
    setIsVerifying(false);
  };

  const toggleLoginModal = (data: {
    isLoginModalOpen: boolean;
    loginModalRedirect?: string;
  }) => {
    if (data.isLoginModalOpen) {
      // added a new page in the browser history in order to trigger the interceptor for the back button using window.onpopstate
      push({}, "");
    } else if (!isEmailLoginModalOpen || !isEmailOtpModalOpen) {
      // console.log("do nothing");
    } else {
      go(-1);
    }
    dispatch(actionSetLoginModalOpen(data));
  };

  const removeEmailLoginSession = () => {
    if (
      LocalStorage.get(EMAIL_LOGIN_VERIFICATION_RESULT) &&
      LocalStorage.get(EMAIL_LOGIN_VERIFICATION_RESULT) === "false"
    ) {
      LocalStorage.remove(EMAIL_LOGIN_VERIFICATION_RESULT);
    }
  };

  const removeGstToken = () => {
    const hostname = window.location.hostname;
    const domain = getDomain(hostname);
    Cookie.remove(COOKIE_GST_ATKN, {
      path: "/",
      domain: domain,
    });
  };

  const removeRedirectSession = () => {
    sessionStorage.removeItem(REDIRECT);
  };

  const toggleEmailLoginModal = (data: boolean) => {
    dispatch(actionSetEmailLoginModalOpen(data));
    dispatch(actionSetOtpErrorMessage(""));
  };

  const toggleEmailOtpModal = (data: boolean) => {
    dispatch(actionSetEmailOtpModalOpen(data));
    dispatch(actionSetOtpErrorMessage(""));
  };

  return {
    handleEmailSignin,
    toggleLoginModal,
    toggleEmailOtpModal,
    toggleEmailLoginModal,
    removeEmailLoginSession,
    removeGstToken,
    removeRedirectSession,
    isVerifying,
    duration,
    handleLoginBasedOnGeoIp,
  };
};
