import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Svg, SVG_ICONS } from "../../components/__common/Svg/Svg";
import { ROUTES_CONSIGNMENT_PAGE } from "../../configs/layout";
import { ROUTES } from "../../configs/routes";
import {
  EVENT_ACTION_MANAGE_COOKIES,
  EVENT_CATEGORY_FOOTER,
  EVENT_FOOTER_ACTION_COOKIE_POLICY,
  EVENT_FOOTER_ACTION_PRIVACY_POLICY,
} from "../../constants/analytics";
import { IS_PROD } from "../../constants/environment";
import { useLayout } from "../../hooks/layout-hooks";
import { ApplicationState } from "../../store";
import { LayoutState } from "../../store/reducers/layout-reducer";
import { SystemState } from "../../store/reducers/site-reducer";
import { UserState } from "../../store/reducers/user-reducer";
import GoogleAnalytics from "../../utils/GoogleAnalytics";
import { UtilsDateTime } from "../../utils/UtilsDateTIme";
import { routeShouldRenderComponent } from "../../utils/UtilsLayout";
import { Typography } from "../__common/Typography/Typography";
import "./FooterMobile.scss";
interface Props {
  onClickLanguage: () => void;
  onClickManageCookie: () => void;
  country?: string;
  language?: string;
  testId?: string;
}

export const FooterMobile: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { setTheme } = useLayout();
  const wrapClass = classNames("responsive--layout");
  const { pathname } = useLocation();

  const {
    sysProps: { BUILD_TIMESTAMP, BUILD_VERSION },
  } = useSelector<ApplicationState, SystemState>((state) => state.system);

  const userState = useSelector<ApplicationState, UserState>(
    (state) => state.user
  );
  const layoutState = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );
  const { userRegion } = userState;

  const handleLanguageOnClick = () => {
    props.onClickLanguage();
  };

  const handleManageCookieClick = (e: any) => {
    e.preventDefault();
    GoogleAnalytics.triggerEvent(
      EVENT_CATEGORY_FOOTER,
      EVENT_ACTION_MANAGE_COOKIES
    );
    props.onClickManageCookie();
  };

  return (
    <div
      data-testid={props.testId}
      className={classNames("footer-mobile", {
        "snap-scroll": pathname === ROUTES.ROOT,
        "consignment-footer": ROUTES_CONSIGNMENT_PAGE.includes(pathname),
      })}
    >
      <div className={wrapClass}>
        {/* <div className="footer-mobile__logo">
          <Svg
            className="arrow-right"
            width={212}
            height={27}
            icon={SVG_ICONS.FOOTER_LOGO}
          />
        </div> */}
        {!routeShouldRenderComponent(pathname, ROUTES_CONSIGNMENT_PAGE) && (
          <div className="footer-mobile__content">
            <div className="footer-mobile__content__link sharp-sans">
              <Link
                data-testid="link-to-privacy-policy"
                to={ROUTES.PRIVACY_POLICY}
                onClick={() =>
                  GoogleAnalytics.triggerEvent(
                    EVENT_CATEGORY_FOOTER,
                    EVENT_FOOTER_ACTION_PRIVACY_POLICY
                  )
                }
              >
                {t("PRIVACY_HEADER_MENU_PRIVACY_POLICY")}
              </Link>
            </div>
            {userRegion.isFromEurope && (
              <>
                <div className="footer-mobile__content__link sharp-sans">
                  <Link
                    data-testid="link-to-cookie-policy"
                    to={ROUTES.COOKIE_POLICY}
                    onClick={() =>
                      GoogleAnalytics.triggerEvent(
                        EVENT_CATEGORY_FOOTER,
                        EVENT_FOOTER_ACTION_COOKIE_POLICY
                      )
                    }
                  >
                    {t("PRIVACY_FOOTER_MENU_COOKIES")}
                  </Link>
                </div>
                <div
                  className={classNames(
                    "footer-mobile__content__link",
                    "footer-mobile__content__language"
                  )}
                >
                  <Link
                    data-testid="mobile-handle-cookie-button"
                    className="footer-mobile__content__language__link sharp-sans"
                    to="#"
                    onClick={(e) => handleManageCookieClick(e)}
                  >
                    {t("PRIVACY_FOOTER_MENU_MANAGE_COOKIES")}
                  </Link>
                </div>
              </>
            )}
            <div
              className={classNames(
                "footer-mobile__content__link",
                "footer-mobile__content__language"
              )}
            >
              <Link
                data-testid="mobile-handle-language-button"
                className={classNames(
                  "footer-mobile__content__language__link sharp-sans",
                  { "max-width": layoutState.countryCode === "VC" }
                )}
                to="#"
                onClick={handleLanguageOnClick}
              >
                <span className="country">
                  {props.country}&nbsp;/&nbsp;
                  {props.language}
                </span>
                <Svg
                  className="arrow-right"
                  icon={SVG_ICONS.ARROW_RIGHT_MOBILE}
                  width={16}
                  height={16}
                />
              </Link>
            </div>
          </div>
        )}
        <div className="footer-mobile__content__copyright sharp-sans">
          Copyright © Samsung Electronics Co, Ltd. All Rights Reserved
        </div>
        <div className="footer-mobile__content__themes">
          <span
            data-testid="hc-toggle-button-on"
            className="icon-link"
            onClick={() => setTheme(true)}
          >
            <Svg
              width={16}
              height={16}
              icon={SVG_ICONS.FOOTER_THEME_HIGH_CONTRAST}
            />
          </span>
          <span
            data-testid="hc-toggle-button-off"
            className={classNames("icon-link", "icon-link-last")}
            onClick={() => setTheme(false)}
          >
            <Svg width={16} height={16} icon={SVG_ICONS.FOOTER_THEME_DEFAULT} />
          </span>
        </div>
        {!IS_PROD && (
          <div className="mobile__content__version">
            <Typography variant="body1" component="div" weight="w700">
              FE:
              <a
                href={`https://github.sec.samsung.net/bdp/sdap/commit/${process.env.REACT_APP_VERSION}`}
              >
                v.{process.env.REACT_APP_ENVIRONMENT}.
                {process.env.REACT_APP_VERSION}
              </a>
              -{UtilsDateTime.formatDate(process.env.REACT_APP_TIMESTAMP!)}
            </Typography>
            <Typography variant="body1" component="div" weight="w700">
              BE:
              <a
                href={`https://github.sec.samsung.net/bdp/sdap/commit/${BUILD_VERSION?.slice(
                  6,
                  BUILD_VERSION.length
                )}`}
              >
                {BUILD_VERSION}
              </a>
              -{UtilsDateTime.formatDate(BUILD_TIMESTAMP)}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};
