import React, { useEffect } from "react";
import { UtilsUser } from "../../utils/UtilsUser";
import DigitalLegacyAuth from "../../pages/nonauth/DigitalLegacyAuth/DigitalLegacyAuth";
import { ApplicationState } from "../../store";
import { UserState } from "../../store/reducers/user-reducer";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { AUTHENTICATED_ROUTES } from "../../configs/routes";
import { URLS } from "../../constants/urls";
import { Cookie } from "../../services/storage";
import { REQUEST_NUMBER } from "../../constants/storage";
import { SPDL_TICKET_NUMBER } from "../../constants/ticketNumberRegex";

interface Props {
  children?: React.ReactNode;
}
const DeepLinkChecker: React.FC<Props> = (props) => {
  const currentURL = window.location.pathname;
  const reqNum = currentURL.split("/").pop();
  const isDLRequestDeepLink = reqNum?.match(SPDL_TICKET_NUMBER);
  const { pathname } = useLocation();
  const { userCountryCode } = useSelector<ApplicationState, UserState>(
    (state) => state.user
  );
  useEffect(() => {
    if (reqNum && isDLRequestDeepLink && !UtilsUser.isUserLoggedIn()) {
      Cookie.set(REQUEST_NUMBER, reqNum);
    }
    if (
      AUTHENTICATED_ROUTES.includes(pathname) &&
      !UtilsUser.isUserLoggedIn()
    ) {
      if (userCountryCode === "CN") {
        window.location.replace(URLS.CHINA_LOCALSITE);
      }
    }
  }, [pathname, userCountryCode]);
  //TO DO: ADD ALL OTHER REDIRECTION SCENARIOS HERE
  return isDLRequestDeepLink && !UtilsUser.isUserLoggedIn() ? (
    <DigitalLegacyAuth />
  ) : (
    <> {props.children}</>
  );
};

export default DeepLinkChecker;
