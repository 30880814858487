/* Regex: ^(SP|SPDL)-(?:DEV|STG|PRE-)?\d+[A-Z0-9]+$ 
This regex validates a specific string format, where the prefix and middle sections may vary or be optional, followed by a number and a suffix.

Breakdown: 
1. ^: Ensures the match starts at the beginning of the string. 
2. (SP|SPDL): - Matches either "SP" or "SPDL" as the required prefix. - The pipe `|` denotes alternation, meaning either "SP" or "SPDL". 
3. -: Matches the literal hyphen after the prefix. 
4. (?:DEV|STG|PRE-)?: - A non-capturing group `(?:...)` is used for the middle prefix. - Matches one of the following: 
a. "DEV" b. "STG" c. "PRE-" (with a trailing hyphen) - The `?` at the end makes this entire group optional, meaning it may or may not be present. 
5. \d+: - Matches one or more digits (0-9). - Represents the numeric part of the string. 
6. [A-Z0-9]+: - Matches one or more uppercase letters (A-Z) or digits (0-9). - Represents the alphanumeric suffix. 
7. $: Ensures the match ends at the end of the string. 

Examples of valid matches: 
1. SP-DEV-2501113333OD2CD // Includes "DEV-" as the middle prefix. 
2. SP-STG-1234567890ABCD // Includes "STG-" as the middle prefix. 
3. SPDL-PRE-555OD4 // Includes "PRE-" as the middle prefix. 
4. SP-1234567890OD2CD // Omits the middle prefix entirely. (FOR PROD REQUEST NUMBER SINCE IT DOESNT HAVE MIDDLE PREFIX)
5. SPDL-555OD4 // Omits the middle prefix entirely. (FOR DIGITAL LEGACY LOGIN IN PROD REQUEST NUMBER SINCE IT DOESNT HAVE MIDDLE PREFIX)

Examples of invalid matches: 
1. SPDL-PROD-1234567890OD2 // Invalid middle prefix "PROD-". 
2. SPDL-DEV- // Missing numeric and alphanumeric parts after "DEV-". 
3. SP- // Missing numeric and alphanumeric parts entirely. */

export const SP_SPDL_TICKET_NUMBER =
  /^(SP|SPDL)-(?:DEV-|STG-|PRE-)?\d+[0-9A-Z]+$/g;

// ONLY FOR ISOLATED CHECKING OF DL TICKET NUMBER
export const SPDL_TICKET_NUMBER = /^(SPDL)-(?:DEV-|STG-|PRE-)?\d+[0-9A-Z]+$/g;
