import "./LoginModal.scss";
import React from "react";
import Modal from "../__common/Modal/Modal";
import { Typography } from "../__common/Typography/Typography";
import { Button } from "../__common/_controls";
import { useAuth } from "../../hooks/auth-hooks";
import { useTranslation } from "react-i18next";
import { Col, Row } from "../Grid/Grid";
import {
  EVENT_ACTION_LOGIN_VIA_EMAIL,
  EVENT_ACTION_LOGIN_VIA_SA,
  EVENT_CATEGORY_LOGIN_OPTIONS,
} from "../../constants/analytics";
import { useLoginOptionHooks } from "../../hooks/login-option-hooks";
import { ApplicationState } from "../../store";
import { useSelector } from "react-redux";
import { LoginOptionState } from "../../store/reducers/login-option-reducer";
import { getRedirectUrl } from "../../utils/RedirectURL";
import GoogleAnalytics from "../../utils/GoogleAnalytics";
import { IS_PRE, IS_PROD } from "../../constants/environment";

interface Props {
  show: boolean;
  onClose: () => void;
  headertestid?: string;
}

export const LoginModal: React.FC<Props> = ({
  show,
  onClose,
  headertestid,
}) => {
  const { login, loginV1 } = useAuth();
  // const query = useQuery();
  const { t } = useTranslation();
  const { toggleEmailLoginModal } = useLoginOptionHooks();
  const { loginModalRedirect } = useSelector<
    ApplicationState,
    LoginOptionState
  >((state) => state.loginOption);
  const handleSaLogin = () => {
    const redirect = getRedirectUrl();

    // close login modal up
    onClose();

    if (IS_PRE || IS_PROD) {
      loginV1(loginModalRedirect || redirect);
    } else {
      login(loginModalRedirect || redirect);
    }

    GoogleAnalytics.triggerEvent(
      EVENT_CATEGORY_LOGIN_OPTIONS,
      EVENT_ACTION_LOGIN_VIA_SA
    );
  };

  return (
    <Modal show={show} className="login-modal" testId="login-modal">
      <Modal.Header headertestid={headertestid} onHide={onClose}>
        <Modal.Title>{t("PRIVACY_LOGIN_OPTION_MODAL_HEADER")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="login-modal__body">
          <Col sm={12} md={6}>
            <Typography variant="title2">
              {t("PRIVACY_LOGIN_OPTION_TITLE_HEADER1")}
            </Typography>
            <Typography variant="body2">
              {t("PRIVACY_LOGIN_OPTION_TEXT1")}
            </Typography>
            <div className="mt20 foccusable-div">
              <Button
                variant="solid"
                className="button"
                title={t("PRIVACY_LOGIN_OPTION_BTN_SA")}
                onClick={handleSaLogin}
                testId="login-via-sa-btn"
              />
            </div>
          </Col>
          <Col sm={12} md={6}>
            <div>
              <Typography variant="title2">
                {t("PRIVACY_LOGIN_OPTION_TITLE_HEADER2")}
              </Typography>
              <Typography variant="body2">
                {t("PRIVACY_LOGIN_OPTION_TEXT2")}
              </Typography>
            </div>

            <div className="mt20 foccusable-div">
              <Button
                title={t("PRIVACY_LOGIN_OPTION_BTN_EMAIL")}
                onClick={() => {
                  GoogleAnalytics.triggerEvent(
                    EVENT_CATEGORY_LOGIN_OPTIONS,
                    EVENT_ACTION_LOGIN_VIA_EMAIL
                  );
                  onClose();
                  toggleEmailLoginModal(true);
                }}
                // className="button"
                testId="login-via-email-btn"
              />
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};
