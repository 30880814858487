import React, { useEffect } from "react";
import Loading from "../../../assets/images/gif/loading_transparent.gif";
import LoadingHC from "../../../assets/images/gif/loading_transparent_HC.gif";
import { Image } from "../../../components/__common/Image";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { LayoutState } from "../../../store/reducers/layout-reducer";
import "./DigitalLegacyAuth.scss";
import { useAuth } from "../../../hooks/auth-hooks";
import {
  DISABLED_FEATURES,
  useFeatureToggle,
} from "../../../utils/feature-toggle/useFeatureToggle";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../configs/routes";
import { IS_PRE, IS_PROD } from "../../../constants/environment";

export const DigitalLegacyAuth: React.FC = () => {
  const history = useHistory();
  const { login, digitalLegacyLogin } = useAuth();
  const { isDisabled } = useFeatureToggle();

  const { isHighContrast } = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );

  useEffect(() => {
    // NOTE:
    // Remove this condition once digital legacy has been released.
    // Remove `DIGITAL_LEGACY` from `DISABLED_FEATURES` enum as well.
    if (isDisabled(DISABLED_FEATURES.DIGITAL_LEGACY)) {
      history.replace(ROUTES.ERROR_404);
      return;
    }

    if (IS_PRE || IS_PROD) {
      digitalLegacyLogin();
    } else {
      login(ROUTES.MYDATA, true);
    }
  }, []);

  return (
    <>
      <Image
        image={!isHighContrast ? Loading : LoadingHC}
        width={60}
        height={60}
        className="dl-route__loading"
      />
    </>
  );
};

export default DigitalLegacyAuth;
