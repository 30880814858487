import { ROUTES, COMMON_NON_AUTH_ROUTES } from "../configs/routes";
import { REDIRECT, REDIRECT_URI, REQUEST_NUMBER } from "../constants/storage";
import { SP_SPDL_TICKET_NUMBER } from "../constants/ticketNumberRegex";
import { Cookie } from "../services/storage";

/**
 * Get the URL where the user is located prior to logging in.
 *
 * This function also handles the redirect URL wherein the user clicks a link
 * in a request email (opt out, deletion, and download).
 */
export const getRedirectUrl = () => {
  const param = sessionStorage.getItem(REDIRECT);
  const pathname = window.location.pathname;
  const requestNumber = Cookie.get(REQUEST_NUMBER);

  if (!param) {
    if (COMMON_NON_AUTH_ROUTES.includes(pathname)) {
      return pathname;
    } else {
      return ROUTES.ROOT;
    }
  }

  let redirect = param; // To avoid mutating the original redirect URL
  const params = param.split("/");

  if (requestNumber) {
    redirect = redirect.replace(`/${requestNumber}`, "");
    if (requestNumber.match(SP_SPDL_TICKET_NUMBER)) {
      params.pop();
      redirect = params.join("/");
      Cookie.set(REDIRECT_URI, `${redirect}/${requestNumber}`);
    }
  }

  return redirect;
};
